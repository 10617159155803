import React from "react";
import Home from "../Pages/Home";
import History from "../Pages/History";
import GuessingGame from "../Pages/GuessingGame";
import DinnerTime from "../Pages/DinnerTime";
import MentalHealth from "../Pages/MentalHealth";
import { Routes, Route } from "react-router-dom";

function navpath() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/history" element={<History />} />
      <Route path="/guessinggame" element={<GuessingGame />} />
      <Route path="/recipefinder" element={<DinnerTime />} />
      <Route path="/mentalhealth" element={<MentalHealth />} />
    </Routes>
  );
}

export default navpath;
