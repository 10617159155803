import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Import the db from firebase.js
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import "../Styles/GuessingGame.css";

function GuessingGame() {
  const [secretNumber, setSecretNumber] = useState(
    Math.trunc(Math.random() * 99) + 1 // Change to 99
  );
  const [score, setScore] = useState(20);
  const [highScore, setHighScore] = useState(0);
  const [playerName, setPlayerName] = useState("");
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  const fetchLeaderboard = async () => {
    const scoresRef = collection(db, "scores");
    const snapshot = await getDocs(scoresRef);

    // Map scores and ensure scores are numbers
    const scores = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        name: data.name,
        score: Number(data.score) || 0, // Ensure score is treated as a number, default to 0 if NaN
        timestamp: data.timestamp, // Add timestamp
      };
    });

    console.log("Fetched scores:", scores); // Log fetched scores

    // Sort by timestamp and slice top 10 most recent scores
    const recentScores = scores
      .sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp descending
      .slice(0, 10); // Get the top 10 recent scores
    setLeaderboard(recentScores);

    // Update the high score
    const maxHighScore = Math.max(
      ...recentScores.map((entry) => entry.score),
      0
    );
    setHighScore(maxHighScore);

    console.log("High Score:", maxHighScore); // Log the high score
  };

  const displayMessage = (message) => {
    document.querySelector(".message").textContent = message;
  };

  const check = async () => {
    const guess = Number(document.querySelector(".guess").value);
    if (!guess) {
      displayMessage("No Number!");
    } else if (guess === secretNumber) {
      displayMessage("Correct!");
      document.querySelector(".number").textContent = secretNumber;
      document.querySelector("body").style.backgroundColor = "#60b347";

      // Save score regardless of whether it's a new high score or not
      await saveHighScore(score, playerName); // Save current score
    } else if (guess !== secretNumber) {
      if (score > 1) {
        displayMessage(guess > secretNumber ? "Too High!" : "Too low!");
        setScore((prevScore) => prevScore - 1);
      } else {
        displayMessage("You lost the game!");
        setScore(0);
      }
    }
  };

  const saveHighScore = async (score, name) => {
    const scoreRef = doc(db, "scores", name);
    await setDoc(
      scoreRef,
      { name, score: Number(score), timestamp: Date.now() },
      { merge: true }
    ); // Add timestamp
    console.log(`Saved score for ${name}: ${score}`); // Log saved score
    await fetchLeaderboard(); // Always fetch leaderboard after saving score
  };

  const reset = () => {
    setScore(20);
    document.querySelector("body").style.backgroundColor =
      "rgba(28, 27, 27, 1)";
    displayMessage("Start Guessing!");
    setSecretNumber(Math.trunc(Math.random() * 99) + 1); // Change to 99
    document.querySelector(".number").textContent = "?";
    document.querySelector(".guess").value = "";
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      event.preventDefault();
      check();
    }
  };
  return (
    <div className="guessing">
      <div className="guessing-content">
        <h1>Guess My Number!</h1>
        <h2>(Between 1 and 99)</h2>
      </div>
      <div className="player-name">
        <input
          type="text"
          placeholder="Your Name"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
        />
      </div>
      <div className="check-guess">
        <input
          type="text"
          onKeyDown={handleKeyPress}
          className="guess"
          pattern="[0-9]*"
          maxLength={2}
        />
        <p className="message">Start Guessing!</p>
        <p2 className="number">?</p2> {/* Use <span> instead of <p2> */}
        <button onClick={check} className="btn-check">
          Check!
        </button>
        <button onClick={reset} className="btn-reset">
          Reset!
        </button>
      </div>
      <div className="scores">
        <p className="current-score">
          Score: <span className="score">{score}</span>
        </p>
        <p className="hi-score">
          Hi-score: <span className="hiscore">{highScore}</span>
        </p>
      </div>
      {/* Leaderboard */}
      <div className="leaderboard">
        <h3>Recent Scores</h3>
        <ol>
          {leaderboard.map((entry) => (
            <li key={entry.id}>
              {entry.name}: {entry.score}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default GuessingGame;
