import { useState } from "react";
import React from "react";
import axios from "axios";
import "../Styles/DinnerTime.css";

function DinnerTime() {
  const [meal, setMeal] = useState(null);

  const generateMeal = async () => {
    try {
      const response = await axios.get(
        `https://www.themealdb.com/api/json/v1/1/random.php`
      );
      const recipe = response.data.meals[0];
      setMeal(recipe);
    } catch (error) {
      console.error("Error fetching the recipe:", error);
    }
  };

  const renderIngredients = () => {
    if (!meal) return null;
    const ingredients = [];
    for (let i = 1; i <= 20; i++) {
      if (meal[`strIngredient${i}`]) {
        ingredients.push(
          <li key={i}>
            {meal[`strIngredient${i}`]} - {meal[`strMeasure${i}`]}
          </li>
        );
      }
    }
    return ingredients;
  };

  return (
    <div className="dinner-body">
      <div className="dinner-header">
        <h1>Need a new recipe?</h1>
        <h2>Click the button below!</h2>
        <button onClick={generateMeal}>Get Recipe!</button>
      </div>
      {meal && (
        <div className="dinner-meal">
          <h3>{meal.strMeal}</h3>
          <img src={meal.strMealThumb} alt={meal.strMeal} />
          <h4>Ingredients:</h4>
          <ul>{renderIngredients()}</ul>
          <h4>Instructions:</h4>
          <p>{meal.strInstructions}</p>
          <a
            href={meal.strSource || meal.strYoutube}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Full Recipe
          </a>
        </div>
      )}
    </div>
  );
}

export default DinnerTime;
