import React from "react";
import "../Styles/MentalHealth.css";
import AOS from "aos";
import "aos/dist/aos.css";

function MentalHealth() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  const [showHello, setShowHello] = React.useState(true);
  const [showGlad, setShowGlad] = React.useState(false);

  React.useEffect(() => {
    const helloTimer = setTimeout(() => {
      setShowHello(false);
      setShowGlad(true);
    }, 2000); // Show "Hello" for 2 seconds

    const gladTimer = setTimeout(() => {
      setShowGlad(false);
    }, 5000); // Show "I'm glad you're here" for 2 seconds after "Hello"

    return () => {
      clearTimeout(helloTimer);
      clearTimeout(gladTimer);
    };
  }, []);
  const letItGO = () => {
    document.querySelector("textarea").value = "";
  };
  return (
    <div className="intro">
      {showHello && <h1>Hello</h1>}
      {showGlad && <p>I'm glad you're here.</p>}
      {!showHello && !showGlad && (
        <div className="mentalHealth">
          <h1 data-aos="fade-up">Get Started Below</h1>
          <p data-aos="fade-up">
            Below is an empty space for you to type your frustrations, thoughts,
            or anything else that is on your mind. This is a safe space for you
            to express yourself. When you are done, click the button below the
            box to let your thoughts go. None of this is saved.
          </p>
          <textarea
            placeholder="Type here..."
            rows="10"
            cols="50"
            data-aos="fade-up"
            style={{ width: "80%", height: "300px" }}
          />
          <button onClick={letItGO} data-aos="fade-up">
            Let it go
          </button>
          <div className="resources">
            <h1 data-aos="fade-up">Free Resources</h1>
            <p data-aos="fade-up">
              Below are some free resources that you can use to help with your
              mental health. Click on the links to be taken to the websites.
            </p>
            <div className="resource-links">
              <a
                data-aos="fade-up"
                href="https://www.nami.org/Home"
                target="_blank"
                rel="noreferrer"
              >
                National Alliance on Mental Illness
              </a>
              <a
                data-aos="fade-up"
                href="https://www.samhsa.gov/"
                target="_blank"
                rel="noreferrer"
              >
                Substance Abuse and Mental Health Services Administration
              </a>
              <a
                data-aos="fade-up"
                href="https://www.mentalhealth.gov/"
                target="_blank"
                rel="noreferrer"
              >
                MentalHealth.gov
              </a>
              <a
                data-aos="fade-up"
                href="https://www.cdc.gov/mentalhealth/tools-resources/index.htm"
                target="_blank"
                rel="noreferrer"
              >
                Centers for Disease Control and Prevention
              </a>
              <a
                data-aos="fade-up"
                href="https://www.7cups.com/"
                target="_blank"
                rel="noreferrer"
              >
                7 Cups
              </a>
              <a
                data-aos="fade-up"
                href="https://www.psychologytoday.com/us/therapists"
                target="_blank"
                rel="noreferrer"
              >
                Find a Therapist
              </a>
              <div className="anonemail">
                <h1 data-aos="fade-up">Send an Anonymous Message</h1>
                <p data-aos="fade-up">
                  If you would like to send me an anonymous message, you can do
                  so below. I will not be able to respond unless you leave
                  contact information, but I will read it.
                </p>
                <form
                  action="https://formspree.io/f/movqawnn"
                  method="POST"
                  data-aos="fade-up"
                >
                  <label>
                    Your Email (optional):
                    <input type="email" name="email" />
                  </label>
                  <label>
                    Your Message:
                    <textarea
                      name="message"
                      rows="5"
                      cols="40"
                      required
                    ></textarea>
                  </label>
                  <button type="submit">Send Anonymously</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default MentalHealth;
