import React from "react";
import { useEffect } from "react";
import "../Styles/Header.css";
import headshot from "../Photos/headshot.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1750,
      once: true,
    });
  }, []);

  return (
    <div className="header">
      <div className="fractal-1"></div>
      <div className="content">
        <h1 data-aos="fade-in">Paul Joplin</h1>
        <div data-aos="fade-in" className="frame">
          <img src={headshot} alt="Headshot of Paul Joplin" />
        </div>
        <h2 data-aos="fade-in">
          Web Designer
          <br />
          Active learner
          <br /> Avid hiker
          <br />
          Coffee enthusiast
        </h2>
      </div>
    </div>
  );
};

export default Header;
