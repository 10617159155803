import { icon } from "@fortawesome/fontawesome-svg-core";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";

export const SidebarMap = [
  {
    title: "Home",
    path: "/",
    icon: <FaIcons.FaHome />,
    cName: "nav-text",
  },
  {
    title: "Résumé",
    path: "/history",
    icon: <BsIcons.BsFileCode />,
    cName: "nav-text",
  },
  {
    title: "Guessing Game",
    path: "/guessinggame",
    icon: <FaIcons.FaQuestion />,
    cName: "nav-text",
  },
  {
    title: "Recipe Finder",
    path: "/recipefinder",
    icon: <FaIcons.FaUtensils />,
    cName: "nav-text",
  },
  {
    title: "Mental Health",
    path: "/mentalhealth",
    icon: <FaIcons.FaBrain />,
    cName: "nav-text",
  },
];
